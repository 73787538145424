import React from "react";
import { Link } from "react-router-dom";

const ContactPage: React.FC = () => {
  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-center text-2xl font-bold mb-4">Kontakt</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Krystian's Contact Information */}
        <div className="p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-2">Krystian Słowik</h2>
          <p className="flex flex-col md:flex-row items-start md:items-center mb-2">
            <strong className="md:w-24">Strona:</strong>
            <a
              href="https://krystianslowik.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:underline"
            >
              krystianslowik.com
            </a>
          </p>
          <p className="flex flex-col md:flex-row items-start md:items-center mb-2">
            <strong className="md:w-24">LinkedIn:</strong>
            <a
              href="https://www.linkedin.com/in/krystianslowik/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:underline"
            >
              linkedin.com/in/krystianslowik
            </a>
          </p>
          <p className="flex flex-col md:flex-row items-start md:items-center mb-2">
            <strong className="md:w-24">Email:</strong>
            <a
              href="mailto:me@krystianslowik.com"
              className="text-gray-500 hover:underline"
            >
              me@krystianslowik.com
            </a>
          </p>
          <p className="flex flex-col md:flex-row items-start md:items-center">
            <strong className="md:w-24">Telefon:</strong>
            <a
              href="tel:+4917684732766"
              className="text-gray-500 hover:underline"
            >
              +49 176 8473 27 66
            </a>
          </p>
        </div>

        {/* Oskar's Contact Information */}
        <div className="p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-2">Oskar Drygalski</h2>
          <p className="flex flex-col md:flex-row items-start md:items-center mb-2">
            <strong className="md:w-24">Strona:</strong>
            <a
              href="https://oskardrygalski.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:underline"
            >
              oskardrygalski.netlify.app
            </a>
          </p>
          <p className="flex flex-col md:flex-row items-start md:items-center mb-2">
            <strong className="md:w-24">LinkedIn:</strong>
            <a
              href="https://www.linkedin.com/in/oskar-drygalski/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:underline"
            >
              linkedin.com/in/oskar
            </a>
          </p>
          <p className="flex flex-col md:flex-row items-start md:items-center mb-2">
            <strong className="md:w-24">Email:</strong>
            <a
              href="mailto:oskar.drygalski@gmail.com"
              className="text-gray-500 hover:underline"
            >
              oskar.drygalski@gmail.com
            </a>
          </p>
          <p className="flex flex-col md:flex-row items-start md:items-center">
            <strong className="md:w-24">Telefon:</strong>
            <a
              href="tel:+48669622625"
              className="text-gray-500 hover:underline"
            >
              +48 669 622 625
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
