// src/components/Upload.tsx

import React, {useState} from "react";
import {
    ArrowUpTrayIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    HeartIcon,
    LockClosedIcon,
} from "@heroicons/react/24/outline";

type UploadProps = {
    isPublic: boolean;
    onFileUpload: (files: File[], isPublic: boolean) => Promise<boolean>;
};

const Upload: React.FC<UploadProps> = ({isPublic, onFileUpload}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = Array.from(event.target.files || []);
        if (files.length === 0) return;

        setLoading(true);
        setUploadStatus(null);

        const success = await onFileUpload(files, isPublic);
        setLoading(false);
        setUploadStatus(success ? "success" : "failed");

        setShowModal(true);
        event.target.value = "";
    };

    const closeModal = () => setShowModal(false);

    // Wybór ikony w zależności od typu przesyłania
    const TypeIcon = isPublic ? HeartIcon : LockClosedIcon;

    return (
        <div
            className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm transition-shadow duration-300 hover:shadow-md">
            <label className="block text-center cursor-pointer">
                <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="sr-only"
                    disabled={loading}
                />
                <div
                    className={`flex flex-col items-center justify-center p-4 ${
                        loading ? "opacity-50" : ""
                    }`}
                >
                    <ArrowUpTrayIcon className="h-10 w-10 text-greyŚ-500 mb-3"/>
                    <p className="text-lg font-medium text-gray-700 mb-1">
                        {isPublic ? "Dodaj publiczne zdjęcia" : "Dodaj prywatne zdjęcia"}
                    </p>
                    <div className="flex items-center text-gray-500">
                        <TypeIcon
                            className={`h-5 w-5 mr-1 ${
                                isPublic ? "text-red-500" : "text-gray-500"
                            }`}
                        />
                        <span className="text-sm">
              {isPublic
                  ? "Widoczne dla wszystkich"
                  : "Widoczne tylko dla Pary Młodej"}
            </span>
                    </div>
                    {loading && (
                        <div className="flex items-center mt-4">
                            <svg
                                className="animate-spin h-5 w-5 text-gray-500 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8v8H4z"
                                ></path>
                            </svg>
                            <span className="text-gray-500 text-sm">Przesyłam...</span>
                        </div>
                    )}
                </div>
            </label>

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 p-2">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative z-50 max-w-sm w-full animate-fade-in">
                        {uploadStatus === "success" ? (
                            <>
                                <CheckCircleIcon className="h-12 w-12 text-green-500 mx-auto mb-4"/>
                                <h2 className="text-xl font-semibold text-center text-gray-800">
                                    Przesyłanie udane!
                                </h2>
                                <p className="text-center text-gray-600 mt-2">
                                    Twoje zdjęcia zostały poprawnie przesłane.
                                </p>
                            </>
                        ) : (
                            <>
                                <ExclamationCircleIcon className="h-12 w-12 text-red-500 mx-auto mb-4"/>
                                <h2 className="text-xl font-semibold text-center text-gray-800">
                                    Przesyłanie nieudane
                                </h2>
                                <p className="text-center text-gray-600 mt-2">
                                    Wystąpił błąd podczas przesyłania zdjęć. Spróbuj ponownie.
                                </p>
                            </>
                        )}
                        <button
                            onClick={closeModal}
                            className="mt-6 w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition-colors duration-300"
                        >
                            Zamknij
                        </button>
                    </div>
                    <div
                        className="fixed inset-0 bg-black bg-opacity-30 z-40 animate-fade-in"
                        onClick={closeModal}
                    ></div>
                </div>
            )}
        </div>
    );
};

export default Upload;
