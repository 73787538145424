import React, { createContext, useState, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useLogin from "../services/useLogin";

interface AuthContextProps {
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();


  const { authenticate, loading } = useLogin();

  const login = async (email: string, password: string) => {
    try {
      const isAuth = await authenticate(email, password);
      if (isAuth) {
        setIsAuthenticated(true);
        navigate("/all-photos");
      } else {
        throw new Error("Invalid email or password");
      }
    } catch (error) {
      console.error("Authentication failed:", error);
      throw error;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    navigate("/login");
  };

  return (
      <AuthContext.Provider value={{ isAuthenticated, login, logout, loading }}>
        {children}
      </AuthContext.Provider>
  );
};

export default AuthProvider;