// src/components/Login.tsx

import React, { useState } from "react";
import { useAuth } from "../store/AuthContext";
import { HeartIcon, LockClosedIcon } from "@heroicons/react/24/outline";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const { login, loading } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      await login(email, password);
    } catch (err) {
      setError("Nieprawidłowy login lub hasło");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
        {/* Ikona serca */}
        <div className="flex justify-center mb-6">
          <HeartIcon className="w-16 h-16 text-red-500" />
        </div>

        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-4">
          Zaloguj się
        </h2>
        <p className="text-center text-gray-600 mb-8">
          Aby uzyskać dostęp do zdjęć, proszę się zalogować
        </p>

        <form onSubmit={handleSubmit}>
          {error && (
            <div className="mb-4 text-red-500 text-center">{error}</div>
          )}

          {/* Pole Email */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              E-mail
            </label>
            <div className="relative">
              <HeartIcon className="w-5 h-5 text-red-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <input
                type="email"
                id="email"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="Podaj adres e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={loading}
              />
            </div>
          </div>

          {/* Pole Hasło */}
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Hasło
            </label>
            <div className="relative">
              <LockClosedIcon className="w-5 h-5 text-red-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <input
                type="password"
                id="password"
                className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="••••••••"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={loading}
              />
              {/* Opcjonalnie: Przełącznik widoczności hasła */}
              {/* <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 focus:outline-none"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeSlashIcon className="w-5 h-5" />
                ) : (
                  <EyeIcon className="w-5 h-5" />
                )}
              </button> */}
            </div>
          </div>

          {/* Przycisk Logowania */}
          <button
            type="submit"
            className={`w-full flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition-colors duration-300 ${
              loading ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={loading}
          >
            {loading ? (
              <svg
                className="w-5 h-5 mr-3 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
            ) : (
              <span>Zaloguj się</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
