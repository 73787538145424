import React from "react";
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const location = useLocation();

  return (
    <footer className="text-gray-400 py-4">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          made with <span className="text-red-600">♥</span> by{" "}
          <a
            href="https://krystianslowik.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-gray-300"
          >
            slowik
          </a>{" "}
          &{" "}
          <a
            className="text-gray-400 hover:text-gray-300"
            rel="noopener noreferrer"
            href="mailto:oskar.drygalski@gmail.com"
            target="_blank"
          >
            oski
          </a>
        </p>
        <p className="text-sm">
          dla{" "}
          <a
            href="https://lemurowelove.pl"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-gray-300 underline"
          >
            lemurowelove
          </a>
        </p>

        {/* Sprawdzenie, czy aktualna ścieżka nie jest '/kontakt' */}
        {location.pathname !== "/kontakt" && (
          <p className="font-bold">
            <a href="/kontakt" className="text-gray-400 hover:text-gray-300">
              skontaktuj się z nami
            </a>
          </p>
        )}
      </div>
    </footer>
  );
};

export default Footer;
