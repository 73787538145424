import React, { useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import MainFoto from "./components/MainFoto";
import Upload from "./components/Upload";
import { uploadPhotos } from "./services/UploadPhotosService";
import Carusel from "./components/Carusel";
import Footer from "./components/Footer";
import Login from "./components/Login";
import AllPhotos from "./components/AllPhotos";
import AuthProvider, { useAuth } from "./store/AuthContext";
import {
  HeartIcon,
  LockClosedIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import PhotoGallery from "./components/PhotoGallery";
import ContactPage from "./components/ContactPage"; // Dodanie komponentu ContactPage

// Function to handle file uploads
const handleFilesUpload = async (
  files: File[],
  isPublic: boolean,
  refreshCarousel: () => void,
  updateGalleryState: (photos: string[], noMorePhotos: boolean) => void,
  photosUrl: string[],
  noMorePhotos: boolean
): Promise<boolean> => {
  try {
    const result = await uploadPhotos(files, isPublic);
    refreshCarousel();

    // If public photos, add them to the gallery
    if (isPublic && result.images) {
      const newPhotoUrls = result.images; // Assuming the server returns { images: string[] }
      // Add new photos to the beginning of the gallery
      updateGalleryState([...newPhotoUrls, ...photosUrl], noMorePhotos);
    } else if (isPublic && result.uploadedUrls) {
      const newPhotoUrls = result.uploadedUrls;
      updateGalleryState([...newPhotoUrls, ...photosUrl], noMorePhotos);
    } else {
      console.error("Uploaded URLs not found in response data.");
    }

    return true;
  } catch (error) {
    console.error("Failed to upload files:", error);
    return false;
  }
};

// Protected Route Component
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Login />;
  }

  return children;
};

// Navigation Component
function Navigation() {
  return (
    <nav className="flex w-full justify-between items-center p-4 bg-white shadow-xs rounded-xl">
      <Link
        to="/"
        className="flex items-center text-red-500 font-semibold text-xl"
      >
        <HeartIcon className="w-6 h-6 mr-2" />
        lemurovelove.pl
      </Link>
      <div>
        <Link
          to="/all-photos"
          className="flex items-center text-gray-600 hover:text-red-600 transition-colors duration-300"
        >
          <LockClosedIcon className="w-5 h-5 mr-1" />
          Prywatne
        </Link>
      </div>
    </nav>
  );
}

const App: React.FC = () => {
  const carouselRef = useRef<{ fetchPhotos: () => void }>(null);

  const refreshCarousel = () => {
    if (carouselRef.current) {
      carouselRef.current.fetchPhotos();
    }
  };

  // Gallery state
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [photosUrl, setPhotosUrl] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [noMorePhotos, setNoMorePhotos] = useState<boolean>(false);

  // Function to update gallery state
  const updateGalleryState = (newPhotos: string[], noMore: boolean) => {
    setPhotosUrl(newPhotos);
    setNoMorePhotos(noMore);
  };

  // Gallery handlers
  const handleShowGallery = () => {
    setGalleryVisible(true);
  };

  const handleCloseGallery = () => {
    setGalleryVisible(false);
    // Do not reset gallery state to preserve loaded photos
  };

  return (
    <Router>
      <AuthProvider>
        <div className="flex flex-col align-super items-center justify-center min-h-screen bg-gray-100 p-4">
          <div className="w-full max-w-4xl mx-auto justify-center">
            <Navigation />
            <Routes>
              {/* Home Route */}
              <Route
                path="/"
                element={
                  <>
                    <MainFoto />
                    <div className="p-4 bg-white rounded-lg shadow-md mb-8">
                      <h2 className="text-xl font-bold mb-2">
                        Prześlij swoje zdjęcia
                      </h2>
                      <div className="text-gray-400 mb-4">
                        Bo po co trzymać je tylko dla siebie?
                      </div>
                      <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex-1">
                          <Upload
                            isPublic={true}
                            onFileUpload={(files, isPublic) =>
                              handleFilesUpload(
                                files,
                                isPublic,
                                refreshCarousel,
                                updateGalleryState,
                                photosUrl,
                                noMorePhotos
                              )
                            }
                          />
                        </div>
                        <div className="flex-1">
                          <Upload
                            isPublic={false}
                            onFileUpload={(files, isPublic) =>
                              handleFilesUpload(
                                files,
                                isPublic,
                                refreshCarousel,
                                updateGalleryState,
                                photosUrl,
                                noMorePhotos
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Carousel Section */}
                    <div className="p-4 bg-white rounded-lg shadow-md">
                      <h2 className="text-xl font-bold mb-2">
                        Ostatnio dodane
                      </h2>
                      <div className="text-gray-400">
                        Po prostu przesuń paluch →
                      </div>
                      <Carusel ref={carouselRef} />
                    </div>

                    {/* Show More Photos Button and Gallery */}
                    <div>
                      <button
                        onClick={handleShowGallery}
                        className="bg-white hover:bg-gray-100 text-gray-700 font-medium py-2 px-4 border border-gray-300 rounded-lg mx-auto w-full my-4 shadow-sm flex items-center justify-center"
                      >
                        <PhotoIcon className="h-5 w-5 text-gray-500 mr-2" />
                        Pokaż więcej zdjęć
                      </button>
                      <PhotoGallery
                        isVisible={galleryVisible}
                        onClose={handleCloseGallery}
                        photosUrl={photosUrl}
                        page={page}
                        setPage={setPage}
                        noMorePhotos={noMorePhotos}
                        updateGalleryState={updateGalleryState}
                      />
                    </div>
                  </>
                }
              />

              {/* Login Route */}
              <Route path="/login" element={<Login />} />

              {/* All Photos Route - Protected */}
              <Route
                path="/all-photos"
                element={
                  <ProtectedRoute>
                    <AllPhotos
                      isVisible={true}
                      onClose={() => console.log("dupa xD")}
                    />
                  </ProtectedRoute>
                }
              />

              {/* Contact Page Route */}
              <Route path="/kontakt" element={<ContactPage />} />
            </Routes>

            <Footer />
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
};

export default App;
