import { api_url } from "../environment/api";

const timestamp = new Date().getTime();

/**
 * Generates the full URL for a photo given its filename.
 * @param filename - The filename of the photo.
 * @returns The complete URL as a string.
 */
const createPhotosUrl = (filename: string): string => {
  return `${api_url}/uploads/${filename}`;
};

/**
 * Fetches a list of photo URLs.
 * This function is used by existing components and returns a simple array of URLs.
 *
 * @param page - The page number for pagination (optional).
 * @param limit - The number of photos per page (optional).
 * @param isPublic - Whether to fetch public photos (`true`) or private photos (`false`). Defaults to `true`.
 * @returns A Promise that resolves to an array of photo URLs (`string[]`).
 */
export const getListOfPhotos = async (
    page?: number,
    limit?: number,
    isPublic: boolean = true
): Promise<string[]> => {
  try {
    let url = `${api_url}/photos/?t=${timestamp}`;

    if (page !== undefined) {
      url += `&page=${page}`;
    }

    if (limit !== undefined) {
      url += `&limit=${limit}`;
    }

    if (!isPublic) {
      url += `&public=${isPublic}`;
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch photos: ${response.statusText}`);
    }

    const data = await response.json();

    if (data.status !== "success") {
      throw new Error("Unexpected response format");
    }

    // Map the data to an array of URLs
    return data.data.map((photo: { filename: string }) => createPhotosUrl(photo.filename));
  } catch (error) {
    console.error("Error fetching photos:", error);
    throw error;
  }
};

/**
 * Fetches both public and private photos, merges them, sorts them by ID in descending order,
 * and returns a sorted array of photo URLs.
 *
 * @param page - The page number for pagination (optional).
 * @param limit - The number of photos per page (optional).
 * @returns A Promise that resolves to a sorted array of merged photo URLs (`string[]`).
 */
export const getMergedPhotos = async (
    page?: number,
    limit?: number
): Promise<string[]> => {
  try {
    // Fetch public and private photos concurrently with IDs
    const [publicPhotos, privatePhotos] = await Promise.all([
      fetchPhotosWithIds(page, limit, true),
      fetchPhotosWithIds(page, limit, false)
    ]);

    // Merge the two lists
    const mergedPhotos = [...publicPhotos, ...privatePhotos];

    // Sort the merged list by ID in descending order
    mergedPhotos.sort((a, b) => b.id - a.id);

    // Extract and return the sorted URLs
    return mergedPhotos.map(photo => createPhotosUrl(photo.filename));
  } catch (error) {
    console.error("Error fetching merged photos:", error);
    throw error;
  }
};

/**
 * Helper function to fetch photos along with their IDs.
 * This is used internally by `getMergedPhotos` for sorting purposes.
 *
 * @param page - The page number for pagination (optional).
 * @param limit - The number of photos per page (optional).
 * @param isPublic - Whether to fetch public photos (`true`) or private photos (`false`).
 * @returns A Promise that resolves to an array of photo objects containing `id` and `filename`.
 */
const fetchPhotosWithIds = async (
    page?: number,
    limit?: number,
    isPublic: boolean = true
): Promise<{ id: number; filename: string }[]> => {
  try {
    let url = `${api_url}/photos/?t=${timestamp}`;

    if (page !== undefined) {
      url += `&page=${page}`;
    }

    if (limit !== undefined) {
      url += `&limit=${limit}`;
    }

    if (!isPublic) {
      url += `&public=${isPublic}`;
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch photos: ${response.statusText}`);
    }

    const data = await response.json();

    if (data.status !== "success") {
      throw new Error("Unexpected response format");
    }

    // Return an array of objects containing ID and filename
    return data.data.map((photo: { id: number; filename: string }) => ({
      id: photo.id,
      filename: photo.filename
    }));
  } catch (error) {
    console.error("Error fetching photos with IDs:", error);
    throw error;
  }
};
