// src/services/UploadPhotosService.ts

import {api_url} from "../environment/api";

export const uploadPhotos = async (files: File[], isPublic: boolean) => {
    const formData = new FormData();

    files.forEach((file) => {
        formData.append("images[]", file);
    });

    formData.append("is_public", isPublic ? "true" : "false");
    !isPublic && formData.append("user_id", "1");

    try {
        const response = await fetch(`${api_url}/upload/bulk`, {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: formData,
        });

        if (!response.ok) {
            let errorMessage = `Failed to upload files: ${response.statusText}`;
            switch (response.status) {
                case 400:
                    errorMessage = "Bad Request: Please check the files and try again.";
                    break;
                case 401:
                    errorMessage = "Unauthorized: Please check your credentials.";
                    break;
                case 403:
                    errorMessage =
                        "Forbidden: You don't have permission to upload these files.";
                    break;
                case 404:
                    errorMessage = "Not Found: The upload URL is incorrect.";
                    break;
                case 500:
                    errorMessage = "Server Error: Please try again later.";
                    break;
                default:
                    errorMessage = `Unexpected error: ${response.statusText}`;
            }
            throw new Error(errorMessage);
        }

        try {
            const data = await response.json();
            console.log("Upload successful:", data);
            return data; // Ensure this includes the uploaded image URLs
        } catch (jsonError) {
            throw new Error("Failed to parse response: Invalid JSON format.");
        }
    } catch (error) {
        if (error instanceof TypeError) {
            console.error("Network error or request aborted:", error);
            throw new Error(
                "Network error: Please check your connection and try again."
            );
        } else {
            console.error("Error uploading files:", error);
            throw error;
        }
    }
};
