// src/components/Modal.tsx

import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ isVisible, onClose, title, children }) => {
  if (!isVisible) return null;

  return (
      <div
          className="fixed inset-0 flex items-center justify-center z-50 p-4 bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={onClose}
      >
        <div
            className="relative w-full max-w-lg bg-white rounded-lg shadow-lg transform transition-all sm:mx-0 sm:my-8 animate-fade-in"
            onClick={(e) => e.stopPropagation()}
        >
          {/* Nagłówek modala */}
          <div className="flex justify-between items-center px-6 py-4 border-b">
            {title && <h2 className="text-xl font-semibold text-gray-800">{title}</h2>}
            <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-600 focus:outline-none"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          {/* Treść modala */}
          <div className="px-6 py-4">
            {children}
          </div>
        </div>
      </div>
  );
};

export default Modal;
