import { useState } from "react";
import {api_url} from "../environment/api";

type UseLoginProps = {
    authenticate: (email: string, password: string) => Promise<boolean>;
    loading: boolean;
};

const useLogin = (): UseLoginProps => {
     const [loading, setLoading] = useState(false);

    const authenticate = async (email: string, password: string): Promise<boolean> => {
        setLoading(true);
        try {
            const response = await fetch(`${api_url}/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });

            const data = await response.json();
            setLoading(false);

            return data.status === "success";
        } catch (error) {
            setLoading(false);
            console.error("Error authenticating:", error);
            return false;
        }
    };

    return { authenticate, loading };
};

export default useLogin;