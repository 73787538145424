import React, {useState, useEffect} from "react";
import {getListOfPhotos} from "../services/GetListOfPhotos";
import {XMarkIcon, ArrowPathIcon} from "@heroicons/react/24/outline";
import Modal from "./Modal";
import {MusicalNoteIcon} from "@heroicons/react/24/outline";

type PhotoGalleryProps = {
    isVisible: boolean;
    onClose: () => void;
    photosUrl: string[];
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    noMorePhotos: boolean;
    updateGalleryState: (photos: string[], noMorePhotos: boolean) => void;
};

const ImageWithPlaceholder: React.FC<{
    src: string;
    alt: string;
    onClick: () => void;
}> = ({src, alt, onClick}) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <div className="relative w-full h-0 pb-[100%] bg-gray-200 overflow-hidden rounded-lg">
            {!loaded && (
                <div className="absolute top-0 left-0 w-full h-full bg-gray-300 rounded-lg animate-pulse-slow"></div>
            )}
            <img
                src={src}
                alt={alt}
                className={`absolute top-0 left-0 w-full h-full object-cover cursor-pointer rounded-lg ${
                    loaded ? "" : "hidden"
                }`}
                onClick={onClick}
                onLoad={() => setLoaded(true)}
            />
        </div>
    );
};

const PhotoGallery: React.FC<PhotoGalleryProps> = ({
                                                       isVisible,
                                                       onClose,
                                                       photosUrl,
                                                       page,
                                                       setPage,
                                                       noMorePhotos,
                                                       updateGalleryState,
                                                   }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const limit = 20;

    const fetchPhotos = async () => {
        setLoading(true);
        try {
            const urls = await getListOfPhotos(page, limit);
            if (urls.length === 0) {
                updateGalleryState(photosUrl, true);
            } else {
                const uniqueUrls = urls.filter((url) => !photosUrl.includes(url));
                updateGalleryState([...photosUrl, ...uniqueUrls], false);
                setPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            console.error("Failed to load photos", error);
            updateGalleryState(photosUrl, true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isVisible && photosUrl.length === 0) {
            fetchPhotos();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const loadMorePhotos = () => {
        if (!noMorePhotos && !loading) {
            fetchPhotos();
        }
    };

    const handlePreview = (url: string) => {
        setPreviewUrl(url);
    };

    const closePreview = () => {
        setPreviewUrl(null);
    };

    return (
        <div
            className={`fixed inset-0 bg-white bg-opacity-95 z-50 overflow-auto p-4 ${
                isVisible ? "" : "hidden"
            }`}
        >
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Galeria zdjęć</h2>
                <button onClick={onClose}>
                    <XMarkIcon className="h-8 w-8 text-gray-700"/>
                </button>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {photosUrl.map((url, index) => (
                    <ImageWithPlaceholder
                        key={index}
                        src={url}
                        alt={`Photo ${index + 1}`}
                        onClick={() => handlePreview(url)}
                    />
                ))}
            </div>

            {loading && (
                <div className="flex justify-center items-center w-full h-64">
                    <ArrowPathIcon className="h-8 w-8 text-gray-500 animate-spin"/>
                </div>
            )}

            {!loading && !noMorePhotos && (
                <button
                    onClick={loadMorePhotos}
                    className="bg-white hover:bg-gray-100 text-gray-700 font-medium py-2 px-4 border border-gray-300 rounded-lg mx-auto my-4 shadow-sm flex items-center justify-center"
                >
                    Załaduj więcej zdjęć
                </button>
            )}

            {!loading && noMorePhotos && (
                <div className="flex flex-col items-center justify-center mt-4">
                    <p className="text-gray-500">To już wszystkie zdjęcia. Zatańczymy?</p>
                    <MusicalNoteIcon className="h-8 w-8 text-gray-500 mt-2"/>
                </div>
            )}

            {previewUrl && (
                <Modal isVisible={!!previewUrl} onClose={closePreview}>
                    <img
                        src={previewUrl}
                        alt="Preview"
                        className="w-full h-auto object-contain rounded-lg"
                    />
                </Modal>
            )}
        </div>
    );
};

export default PhotoGallery;
