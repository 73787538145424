import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {getListOfPhotos} from "../services/GetListOfPhotos";
import Modal from "./Modal";

const Carousel = forwardRef((props, ref) => {
    const [photosUrl, setPhotosUrl] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState<string | null>(null);

    const fetchPhotos = async () => {
        setLoading(true);
        try {
            const urls = await getListOfPhotos(1, 20);
            setPhotosUrl(urls);
        } catch (error) {
            console.error("Error fetching photos for carousel:", error);
        } finally {
            setLoading(false);
        }
    };

    useImperativeHandle(ref, () => ({
        fetchPhotos,
    }));

    useEffect(() => {
        fetchPhotos();
    }, []);

    const handleImageClick = (url: string) => {
        setSelectedPhotoUrl(url);
    };

    const closeModal = () => {
        setSelectedPhotoUrl(null);
    };

    return (
        <>
            <div className="flex overflow-x-auto space-x-4 max-w-full p-4">
                {loading
                    ? Array.from({length: 5}).map((_, index) => (
                        <div
                            key={index}
                            className="w-64 h-64 flex-shrink-0 bg-gray-300 animate-pulse rounded-lg shadow-md"
                        ></div>
                    ))
                    : photosUrl.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            className="w-64 h-64 flex-shrink-0 object-cover rounded-lg shadow-md cursor-pointer"
                            onClick={() => handleImageClick(url)}
                            alt={`Carousel Image ${index + 1}`}
                        />
                    ))}
            </div>

            {/* Modal for image preview */}
            {selectedPhotoUrl && (
                <Modal isVisible={!!selectedPhotoUrl} onClose={closeModal}>
                    <img
                        src={selectedPhotoUrl}
                        alt="Preview"
                        className="w-full h-auto object-contain rounded-lg"
                    />
                </Modal>
            )}
        </>
    );
});

export default Carousel;
