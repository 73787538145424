import React from "react";
import { HeartIcon } from "@heroicons/react/24/outline";

const MainFoto: React.FC = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md my-8">
      <div className="flex items-center justify-center mb-2">
        <h2 className="text-xl font-bold text-center mr-2">Lemurowe Love</h2>
        <HeartIcon className="w-4 text-red-500" />
      </div>
      <div className="flex items-center justify-center mb-4">
        <img
          className="rounded-lg border-4 border-white max-w-full h-auto"
          src="/mainfoto.jpg"
          alt="Para młoda"
        />
      </div>

      <p className="text-gray-600 text-center">
        Uwiecznione chwile pełne miłości i radości, których wspomnienia
        przetrwają całe życie.
      </p>
    </div>
  );
};

export default MainFoto;
